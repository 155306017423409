import React, { useState } from 'react';
import { useContentManager } from '@hooks';
import Main from '@components/containers/exportables/main';
import NoData from '@components/containers/exportables/ui/no-data';
import Table from '@components/containers/exportables/ui/table';
import Modal from '@components/containers/exportables/ui/modal';
import TableRow from '@components/containers/exportables/ui/table/table-row/scheduled';
import CreateReport from '@components/containers/exportables/create-report';
import { frequencyScheduleList,frequencyList, getDefaultSelectedFilters, getReportList, parseFilters, frequencyHourlyOnly } from '../main/utils';
import server from '@components/containers/exportables/server';
import mixpanel from '@services/mixpanel';
import { useSelector } from 'react-redux';
import config from '@config';
import { useSplit } from '../../../../hooks/use-split';
import { constants as splitConstants } from '../../../../utils/split.io';

const headCells = [
    { id: 'name', label: <span>Name</span> },
    { id: 'type', label: <span>Type</span> },
    { id: 'createdAt', label: <span>Start Date</span> },
    { id: 'createdBy', label: <span>Created By</span> },
    { id: 'frequency', label: <span>Frequency</span> },
    { id: 'lastRun', label: <span>Last Run</span> },
    { id: 'options', label: '' },
];

export default () => {
    const cm = useContentManager();
    const [reports, setReports] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [reportData, setReportData] = useState({
        id: '',
        name: '',
        action: '',
        data: {},
    });
    const [data, setData] = useState({});
    let finalFrequencyScheduleList = frequencyScheduleList;
    const { isOn: isHourlyClient } = useSplit(splitConstants.PARADOME_HOURLY_SCHEDULE_REPORT_CLIENTS);
    if (isHourlyClient) {
        finalFrequencyScheduleList = [...frequencyHourlyOnly, ...finalFrequencyScheduleList];
    }
    const { networkId, username, dataLocationId } = JSON.parse(localStorage.getItem('meta') || '{}');

    const { selectedLicense } = useSelector(state => state.settings);
    const isAffiliateFraudEnabled = useSelector(state => state.settings.isAffiliateFraudEnabled);
    const { isOn: showComprehensiveDetectionReport } = useSplit(splitConstants.PARADOME_COMPREHENSIVE_DETECTION_REPORT);
    const { isOn: showExtendedResponseClientReport } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_REPORT);
    const { isOn: showExtendedResponseClientForMIS } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_FOR_MIS);
    const { isOn: limitExtendedReportsToOss } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_LIMIT_ONLY_TO_OSS);
    const { isOn: isNewSlpDashboardOn } = useSplit(splitConstants.PARADOME_NEW_SLP_DASHBOARD);

    const finalReportTypes = getReportList(
        selectedLicense,
        isAffiliateFraudEnabled,
        showComprehensiveDetectionReport,
        showExtendedResponseClientReport,
        showExtendedResponseClientForMIS,
        limitExtendedReportsToOss,
        isNewSlpDashboardOn
    );

    const fetchReports = async ({ selected }) => {
        const { networkId, dataLocationId } = JSON.parse(localStorage.getItem('meta') || '{}');
        selected = getDefaultSelectedFilters(selected, finalReportTypes, isHourlyClient ? finalFrequencyScheduleList : frequencyList);
        let params = parseFilters({ selected });
        try {
            if (params.length > 0) params = '?' + params;
            const skewedUrl = dataLocationId != 2 ? config.skewedAnalyticsReports : config.skewedAnalyticsReportsEU;
            const res = await server.get(`/scheduled/${networkId}${params}`, skewedUrl);
            setReports(res.data);
        } catch (e) {
            console.log(e && e.message);
        }
    };

    const onActionHandler = (action, reportId, reportName, reportData) => {
        setShowModal(true);
        setReportData(prevState => ({
            ...prevState,
            id: reportId,
            name: reportName,
            data: reportData,
            action,
        }));
    };

    const onApproveActionHandler = async () => {
        switch (reportData.action) {
            case 'delete':
                await onDeleteReportHandler(reportData.id, reportData.data, dataLocationId);
                break;
            case 'pause':
            case 'resume':
                await enableDisableReport(reportData.action, reportData.id, dataLocationId);
                break;
        }
    };

    const onEditReport = data => {
        setData(data);
        setShowDrawer(true);
        mixpanel.postEvent('Exportable schedule report edit', createDataForMixpanel(data));
    };

    const createDataForMixpanel = data => {
        return {
            'Schedule report type': data.type,
            'Schedule report frequency': data.frequency,
            'Schedule report created date': data.createdAt,
            'Schedule report last run': data.lastRun,
        };
    };

    const onDeleteReportHandler = async (id, data, dataLocationId) => {
        await server.delete(`${id}`, dataLocationId != 2 ? config.skewedAnalyticsScheduledReports : config.skewedAnalyticsScheduledReportsEU);
        mixpanel.postEvent('Exportable schedule report delete', createDataForMixpanel(data));
        await fetchReports({});
    };

    const enableDisableReport = async (action, reportId, dataLocationId) => {
        const data = await server.get(`/${reportId}/data`, dataLocationId != 2 ? config.skewedAnalyticsScheduledReports : config.skewedAnalyticsScheduledReportsEU);
        const report = data.data;
        report.enabled = action !== 'pause';
        report.networkId = networkId;
        report.createdBy = username;
        await server.put(``, report, dataLocationId != 2 ? config.skewedAnalyticsScheduledReports : config.skewedAnalyticsScheduledReportsEU);
        mixpanel.postEvent(action === 'pause' ? 'Exportable schedule report pause' : 'Exportable schedule report resume', createDataForMixpanel(reportData.data));
        await fetchReports({});
    };

    return (
        <>
            <Main
                body={
                    reports && reports.length === 0 ? (
                        <NoData />
                    ) : (
                        <Table
                            cm={cm}
                            headCells={headCells}
                            reports={reports}
                            TableRow={TableRow}
                            reportMethods={{
                                onActionHandler,
                                onEditReport,
                            }}
                        />
                    )
                }
                removeStatusFilter
                schedulerReports
                headerLabel="Scheduled reports"
                headerButtonText={cm.get('ScheduleReportButtonText')}
                totalReports={reports.length}
                reports={reports}
                fetchReports={fetchReports}
            />
            {showDrawer && (
                <CreateReport
                    cm={cm}
                    showDrawer={showDrawer}
                    title="EditReport"
                    setShowDrawer={setShowDrawer}
                    data={data}
                    scheduleReportsOnly
                    editScheduledReport
                    fetchReports={fetchReports}
                    reportTypes={finalReportTypes}
                    frequencyList={finalFrequencyScheduleList}
                />
            )}
            {showModal && <Modal cm={cm} reportData={reportData} onApproveHandler={onApproveActionHandler} onClose={() => setShowModal(!showModal)} />}
        </>
    );
};

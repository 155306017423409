import React, { useEffect, useState } from 'react';
import Select from '@components/containers/exportables/ui/select';
import { getFormOptions } from '@components/containers/exportables/main/utils';
import DatePicker from '@components/containers/exportables/ui/date-picker';

import styles from '../../create-report.module.scss';
import { parseDate } from '@utils/skewed';

export default ({ state, updateState, onChangeHandler, cm, scheduleReportsOnly, data, frequencyList }) => {
    const { frequency, dateRange, type } = state;
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    useEffect(() => {
        if (frequency !== 'onetime' && !data) {
            updateState(prevState => ({
                ...prevState,
                frequency: type === 'limited' ? 'monthly' : 'daily',
            }));
        }
    }, [type]);

    const isRepeat = () => {
        return frequency !== 'onetime' && frequency !== 'daily';
    };

    const handleOnDateChange = ({ target: { name, value } }) => {
        if (value === 'Custom') return setDatePickerOpen(true);
        const { details } = getFormOptions().dateRange.find(range => range.value === value);
        updateState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value, format: parseDate(details) },
        }));
    };

    const handleCustomDateRange = format => {
        const custom = getFormOptions().dateRange.find(range => range.value === 'Custom');
        updateState(prevState => ({
            ...prevState,
            dateRange: { ...custom, format },
        }));
    };

    const handelCloseDateRangePicker = date => {
        if (date.length <= 1) {
            updateState(state);
        }
        setDatePickerOpen(false);
    };

    const getRepeatLine = () => {
        if (frequency === 'weekly') return 'On Mondays';
        else if (frequency !== 'hourly') return 'On every 1st of the month';
    };

    return (
        <div className={styles.inputContainer} data-test-selector="exportables-create-report-frequency">
            <div className={styles.label}>{cm.get('Frequency')}</div>
            <div className={styles.flexItem}>
                <Select
                    cm={cm}
                    smallWidth
                    name="frequency"
                    selected={frequency}
                    onChange={onChangeHandler}
                    options={frequencyList}
                    selector="exportables-create-report-frequency-select"
                />
                {frequency === 'onetime' && (
                    <>
                        <Select
                            cm={cm}
                            smallWidth
                            name="dateRange"
                            selected={dateRange?.value}
                            onChange={handleOnDateChange}
                            options={getFormOptions().dateRange}
                            selector="exportables-create-report-frequency-onetime-select"
                        />
                        <DatePicker
                            open={datePickerOpen}
                            value={dateRange?.format || []}
                            options={{
                                onChange: handleCustomDateRange,
                                onClose: handelCloseDateRangePicker,
                            }}
                            className={styles.datePickerInput}
                        />
                    </>
                )}
                {isRepeat() && (
                    <>
                        <div className={styles.text}>{getRepeatLine()}</div>
                    </>
                )}
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import Loader from '@components/widgets/progress';
import Header from '@components/containers/exportables/ui/header';
import Filters from '@components/containers/exportables/ui/filters-list';
import styles from '../exportables.module.scss';
import CreateReport from '../create-report';
import { useContentManager } from '@hooks';
import { filterItems, frequencyList, statusFilter, getUsersFilter, getReportList, frequencyScheduleList, frequencyHourlyOnly } from './utils';
import Snackbar from '@components/containers/exportables/ui/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setReportFilters } from '@redux/actions/skewed';
import mixpanel from '@services/mixpanel';
import { useSplit } from '../../../../hooks/use-split';
import { constants as splitConstants } from '../../../../utils/split.io';

const REPORTS_INTERVAL = 3000;

export default ({ body, removeStatusFilter, headerLabel, headerButtonText, totalReports, reports, fetchReports, schedulerReports }) => {
    const cm = useContentManager();
    const dispatch = useDispatch();
    const isAffiliateFraudEnabled = useSelector(state => state.settings.isAffiliateFraudEnabled);
    const currentReportsFilters = useSelector(state => state.skewed.currentReportsFilters);
    const { selectedLicense } = useSelector(state => state.settings);

    const { isOn: showComprehensiveDetectionReport } = useSplit(splitConstants.PARADOME_COMPREHENSIVE_DETECTION_REPORT);
    const { isOn: showExtendedResponseClientReport } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_REPORT);
    const { isOn: showExtendedResponseClientForMIS } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_CLIENT_FOR_MIS);
    const { isOn: limitExtendedReportsToOss } = useSplit(splitConstants.PARADOME_EXTENDED_RESPONSE_LIMIT_ONLY_TO_OSS);
    const { isOn: isNewSlpDashboardOn } = useSplit(splitConstants.PARADOME_NEW_SLP_DASHBOARD);
    const { isOn: isHourlyClient } = useSplit(splitConstants.PARADOME_HOURLY_SCHEDULE_REPORT_CLIENTS);


    const finalReportTypes = getReportList(
        selectedLicense,
        isAffiliateFraudEnabled,
        showComprehensiveDetectionReport,
        showExtendedResponseClientReport,
        showExtendedResponseClientForMIS,
        limitExtendedReportsToOss,
        isNewSlpDashboardOn
    );
    let filterFrequencyList = selectedLicense === 1 ? frequencyList.filter(item => item.id === 1) : frequencyList;

    let filterData = filterItems;
    if (removeStatusFilter) {
        filterData = filterData.filter(item => item.id !== 4);
    }

    if (schedulerReports) filterFrequencyList = frequencyScheduleList;
    if (isHourlyClient) {
        filterFrequencyList = [...frequencyHourlyOnly, ...filterFrequencyList];
    }
    let scheduleReportsOnly = false;
    if (schedulerReports) scheduleReportsOnly = true;

    const [createdByFilter, setCreatedByFilter] = useState([]);
    const [selected, setSelected] = useState({
        type: finalReportTypes,
        users: [],
        status: [],
        frequency: filterFrequencyList,
        searchValue: '',
    });
    const [loading, setLoading] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState({
        reportFrequency: 'onetime',
        isSuccess: true,
        code: 0,
    });

    useEffect(async () => {
        setLoading(true);
        dispatch(setReportFilters({ ...selected }));
        await fetchReports({ selected });
        setLoading(false);
    }, []);

    useEffect(async () => {
        if (createdByFilter.length === 0) {
            setCreatedByFilter(getUsersFilter(reports));
        }
    }, [reports]);

    useEffect(() => {
        if (!schedulerReports && !loading) {
            const intervalReport = setInterval(async () => await fetchReports({ selected: currentReportsFilters }), REPORTS_INTERVAL);
            if (!reports?.length) clearInterval(intervalReport);
            return () => clearInterval(intervalReport);
        }
    }, [loading, reports]);

    useEffect(async () => {
        setLoading(true);
        await fetchReports({ selected });
        setLoading(false);
    }, [selected]);

    const onSnackbarHandler = (frequency, isSuccess, code) => {
        setSnackbarType(prevState => ({
            ...prevState,
            reportFrequency: frequency,
            isSuccess,
            code,
        }));
        setShowSnackbar(true);
    };

    const onSearchHandler = async e => {
        e.preventDefault();
        const value = e.target.value;
        setSelected(prevState => ({
            ...prevState,
            searchValue: value,
        }));
        dispatch(setReportFilters({ ...selected, searchValue: value }));
    };

    const onClearHandler = async () => {
        setSelected(prevState => ({
            ...prevState,
            searchValue: '',
        }));
        dispatch(setReportFilters({ ...selected, searchValue: '' }));
    };

    const onClickHeaderButtonHandler = () => {
        setShowDrawer(!showDrawer);
        mixpanel.postEvent('Exportable new report button click', {});
    };

    return (
        <div className={styles.container} data-test-selector="exportables-main-screen">
            <Header
                title={headerLabel}
                headerButtonText={headerButtonText}
                count={totalReports}
                searchValue={selected.searchValue}
                onSearchHandler={onSearchHandler}
                onClick={onClickHeaderButtonHandler}
                onClearHandler={onClearHandler}
            />
            <Filters
                cm={cm}
                list={filterData}
                selected={selected}
                setSelected={setSelected}
                typeFilter={finalReportTypes}
                createdByFilter={createdByFilter}
                frequencyFilter={filterFrequencyList}
                statusFilter={statusFilter}
            />
            {loading ? <Loader /> : body}
            {showDrawer && (
                <CreateReport
                    cm={cm}
                    reportTypes={finalReportTypes}
                    frequencyList={filterFrequencyList}
                    reports={reports}
                    showDrawer={showDrawer}
                    title={schedulerReports ? 'ScheduleReportButtonText' : 'CreateReport'}
                    setShowDrawer={setShowDrawer}
                    setShowSnackbar={onSnackbarHandler}
                    fetchReports={fetchReports}
                    scheduleReportsOnly={scheduleReportsOnly}
                />
            )}
            {showSnackbar && <Snackbar cm={cm} open={showSnackbar} onClose={() => setShowSnackbar(false)} config={snackbarType} />}
        </div>
    );
};
